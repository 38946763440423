import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import { Container } from "../components/Container.styles"
import { Text } from "../components/Text/Text"
import { Stack } from "../components/Stack"
import { Link } from "../components/Link"
import { HorizontalRule } from "../components/HorizontalRule"

export default (): JSX.Element => (
  <Layout>
    <SEO title="About" />
    <Container>
      <div style={{ maxWidth: 590, margin: "0 auto" }}>
        <Stack space="xl">
          <section>
            <Text as="h1" variant="heading-1">
              About Me
            </Text>
            <HorizontalRule css={{ maxWidth: "600px" }} />
            <Text as="p" variant="body-1" css={{ lineHeight: 2.0 }}>
              I'm a professional software engineer, with a bachelor's degree in
              Computer Science from Kingston University.
              <br />
              <br />
              I've been programming since 2011, but started professionally in
              2016 (after my degree). I'm a lover of accessible, user focused
              web development. Anything that delights a user or adds a little
              sparkle is right up my street. Frontend is where I'm at, but I'm a
              very capable full stack engineer.
              <br />
              <br />
              You’ll often find me in an outrageously funky shirt, eating a
              bunch of{" "}
              <Link to="https://en.wikipedia.org/wiki/Doughnut#United_Kingdom">
                yum yums
              </Link>
              , working with Typescript, Javascript, Node, Next.js and maybe
              other languages that pick my fancy.
              <br />
              <br />
              If you’re interested, check out some of my{" "}
              <Link to="/projects/">projects.</Link>
            </Text>
            <Text as="p" variant="body-1">
              If you're super keen to find out what was here,{" "}
              <Link to={"mailto: mail@malicoxon.co.uk"}>drop me an email.</Link>
            </Text>
          </section>
          <section>
            <Stack space="l">
              <Text as="h1" variant="heading-2">
                Contact me! I don't bite!{" "}
                <sup>
                  <em>(much!)</em>
                </sup>
              </Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Link to="https://www.linkedin.com/in/mali-coxon/">
                  <Text as="span" variant="body-1">
                    LinkedIn
                  </Text>
                </Link>
                <Link to="https://twitter.com/mali_coxon">
                  <Text as="span" variant="body-1">
                    Twitter
                  </Text>
                </Link>
                <Link to="https://github.com/slooowpoke/">
                  <Text as="span" variant="body-1">
                    Github
                  </Text>
                </Link>
                <Link to="mailto: mail@malicoxon.co.uk">
                  <Text as="span" variant="body-1">
                    Email
                  </Text>
                </Link>
              </div>
            </Stack>
          </section>
        </Stack>
      </div>
    </Container>
  </Layout>
)
